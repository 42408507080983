<template>
  <div class="datapeople-app">
    <v-app>
      <router-view
        v-slot="{ Component }"
        @track-page-load="trackPageLoad"
      >
        <component :is="Component" />
      </router-view>
      <FeedbackButton />
    </v-app>
  </div>
</template>
<script>
import { debounce, throttle, startCase } from 'lodash-es';
import { mapStores, mapActions } from 'pinia';
import { useAppAuthData } from '@/stores/app/authData';
import { useTracking } from '@/stores/tracking/tracking';
import { useModals } from '@/stores/modals/modals';
import * as Sentry from '@sentry/vue';
import { useAppAlerts } from '@/stores/app/alerts';
import semver from 'semver';
import { useAppBase } from '@/stores/app/base';
import { getReadableTimezone } from '@/helpers/timeZone';
import FeedbackButton from '@/components/shared/FeedbackButton.vue';
import { personaOptions } from '@/components/settings/account/persona';
import { TYPE_TAPWAGE } from '@/helpers/constants/integrationTypes';
import { getEnvironment } from '@/helpers/environment';

export default {
  name: 'App',
  components: {
    FeedbackButton,
  },
  head() {
    return { title: this.$route.meta?.title || 'Datapeople' };
  },
  computed: {
    ...mapStores(useAppAuthData),
  },
  watch: {
    '$route.name': {
      handler() {
        this.resetModals();

        this.$nextTick(() => {
          if (
            !this.appAuthDataStore.authData.personaKey
              && !window.sessionStorage.getItem('dismissedPersonaModal')
              && !this.appAuthDataStore.isDatapeopleEmployee
              && this.appAuthDataStore.authData.isLoggedIn
              && this.$route.query?.view !== 'chrome'
          ) {
            useModals().setModal({ field: 'persona', value: true });
          }

          const isDatapeopleAnywhere = this.$route.query?.view === 'chrome';

          if (isDatapeopleAnywhere) Sentry.setTag('isDatapeopleAnywhere', isDatapeopleAnywhere);
        });
      },
    },
    'appAuthDataStore.authData.userId': {
      handler(newValue) {
        if (newValue) {
          const {
            email,
            firstName,
            lastName,
            projectName,
            integrationType,
            integrationTypeKey,
            atsId,
            role,
            personaKey,
            atsName,
            projectId,
          } = this.appAuthDataStore.authData;
          const timezone = getReadableTimezone();
          const persona = personaOptions[personaKey] || 'Not Specified';

          Sentry.setUser({
            id: newValue,
            email,
            username: `${firstName}${lastName ? ` ${lastName}` : ''}`,
            ip_address: '{{auto}}',
          });

          if (projectName) Sentry.setTag('projectName', projectName);
          if (integrationType) Sentry.setTag('integration', integrationType);
          if (atsId) Sentry.setTag('atsId', atsId);
          if (role) Sentry.setTag('role', startCase(role));
          if (timezone) Sentry.setTag('timezone', timezone);
          if (persona) Sentry.setTag('persona', persona);

          const shouldIdentifyUser = getEnvironment() === 'production';

          if (window.Beacon && shouldIdentifyUser) {
            window.Beacon('identify', {
              email,
              name: `${firstName}${lastName ? ` ${lastName}` : ''}`,
              company: projectName,
              'e-mail': email,
              'integration-status': !integrationTypeKey || integrationTypeKey === TYPE_TAPWAGE ? 'Non-Integrated' : 'Integrated',
              'project-id': projectId,
              'project-name': projectName,
              'admin-status': startCase(role),
              ats: atsName,
              persona,
            });
          }
        } else {
          Sentry.setUser(null);
        }
      },
    },
    'appAuthDataStore.authData.appVersion': {
      handler(newValue) {
        // Handles if the API has higher version than the app and prompts a refresh to break cache
        if (
          newValue
          && newValue !== process.env.VUE_APP_PACKAGE_VERSION
          && semver.gt(newValue, process.env.VUE_APP_PACKAGE_VERSION)
        ) {
          this.addAlert({
            type: 'warning',
            message: 'New app version available! Please refresh your browser to get the latest version.',
          });
        }
      },
    },
  },
  created() {
    // Disable Grammarly
    const callback = () => {
      const grammarlyExtension = document.getElementsByTagName('GRAMMARLY-EXTENSION')[0];

      if (grammarlyExtension) {
        grammarlyExtension.remove();
      }
    };

    const debouncedCallback = debounce(callback, 2000);

    const observer = new MutationObserver(debouncedCallback);
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });

    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
    document.addEventListener('keypress', this.onKeyPress);
    window.addEventListener('resize', this.onResize, true);
    document.addEventListener('scroll', this.onScroll);
    document.addEventListener('copy', this.checkClipboardPermissions);
    document.addEventListener('paste', this.checkClipboardPermissions);
    window.addEventListener('popstate', this.handleBrowserBackOrForward);
  },

  mounted() {
    this.setWindowWidth({ width: window.innerWidth });
  },

  unmounted() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
    document.removeEventListener('keypress', this.onKeyPress);
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('copy', this.checkClipboardPermissions);
    document.removeEventListener('paste', this.checkClipboardPermissions);
    document.removeEventListener('popstate', this.handleBrowserBackOrForward);
  },
  methods: {
    ...mapActions(useModals, ['resetModals']),
    ...mapActions(useTracking, ['trackPageLoad']),
    ...mapActions(useAppAlerts, ['addAlert']),
    ...mapActions(useAppBase, ['keyEvent', 'setScroll', 'setWindowWidth', 'checkClipboardPermissions', 'setBrowserBackOrForwardNavigation']),

    handleBrowserBackOrForward() {
      this.setBrowserBackOrForwardNavigation(true);
    },
    onKeyEvent(type, event) {
      this.keyEvent({ type, event });
    },
    onKeyDown(event) {
      this.onKeyEvent('down', event);
    },
    onKeyUp(event) {
      this.onKeyEvent('up', event);
    },
    onKeyPress(event) {
      this.onKeyEvent('press', event);
    },
    onResize() {
      this.onResizeThrottled();
    },
    onResizeThrottled: throttle(function onResizeThrottledCallback() {
      this.setWindowWidth({ width: window.innerWidth });
    }, 100),
    onScroll(event) {
      this.onScrollThrottled(event);
    },
    onScrollThrottled: throttle(function onScrollThrottledCallback(event) {
      this.setScroll({ scrollY: window.scrollY, event });
    }, 200),
  },
};
</script>
<style lang="less" src="./app.less" />
