import { getRequest, postRequest, postRequestWithSuccessErrorsResponse } from './request';

export const login = async ({ email, password }) => {
  const data = await postRequest({
    url: '/signup/login',
    body: { email, password },
  });

  return {
    success: data.success,
    email: data.email,
    errors: data.errors,
    mixpanel: data.mixpanel,
    user: data.user,
  };
};

export const isLoggedIn = async () => {
  const data = await getRequest({ url: '/signup/is-logged-in' });

  // TODO: fix camel-case
  return { is_logged_in: data.is_logged_in };
};

export const signupBuildAuthUrls = async ({ inviteKey = null, from = '' } = {}) => {
  let fromParam = '';
  if (from === 'datapeople-anywhere') {
    fromParam = '?from=datapeople-anywhere';
  }
  if (from.startsWith('help')) {
    fromParam = `?from=${from}`;
  }

  const data = await postRequest({
    url: `/signup/build-auth-urls${fromParam}`,
    body: { inviteKey },
  });

  return {
    success: data.success,
    // TODO: fix camel-case
    google_auth_url: data.google_auth_url,
    microsoft_auth_url: data.microsoft_auth_url,
  };
};

export const isSuperuser = async () => {
  const data = await getRequest({ url: '/signup/is-superuser' });

  // TODO: fix camel-case
  return { is_superuser: data.is_superuser };
};

export const signupCreateAccount = async ({
  firstName, lastName, email, password,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/create-account',
  body: {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
  },
});

export const signupCreateLeadUserDemo = async ({
  firstName, lastName, email,
}) => {
  const data = await postRequest({
    url: '/signup/create-lead-user-demo',
    body: { first_name: firstName, last_name: lastName, email },
  });

  return {
    success: data.success,
    errors: data.errors,
    // TODO: fix camel-case
    has_company_info: data.has_company_info,
    project_id: data.project_id,
  };
};

export const signupSetPassword = async ({
  passwordResetToken, password, confirmPassword, email,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/set-password',
  body: {
    password_reset_token: passwordResetToken,
    email,
    password,
    confirm_password: confirmPassword,
  },
});

export const signupVerifyEmail = async ({
  confirmationKey,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/verify-email',
  body: { confirmation_key: confirmationKey },
});

export const signupSubmitAts = async ({
  company, url, ats,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/submit-ats',
  body: { company_name: company, jobboard_url: url, ats_id: ats },
});

export const signupSubmitAtsDemoRequest = async ({
  projectId, company, url, ats,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/submit-ats-demo-request',
  body: {
    project_id: projectId,
    company_name: company,
    jobboard_url: url,
    ats_id: ats,
  },
});

export const signupAcceptTos = async ({
  acceptedTos,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/accept-tos',
  body: { accepted_tos: acceptedTos },
});

export const signupJoinDashboard = async ({
  firstName,
  lastName,
  email,
  password,
  inviteKey,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/join-dashboard',
  body: {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    invite_key: inviteKey,
  },
});

export const signupGetProjectNameAndImage = async ({ key }) => {
  const data = await getRequest({ url: `/signup/get-project-name-and-image?key=${key}` });

  return {
    success: data.success,
    errors: data.errors,
    projectName: data.project_name,
    imageUrl: data.image_url,
    ssoName: data.sso_name,
    ssoMandatory: data.sso_mandatory,
    ssoIsGoogle: data.sso_is_google,
    ssoIsMicrosoft: data.sso_is_microsoft,
  };
};

export const signupIsBilling = async () => {
  const data = await getRequest({ url: '/signup/is-billing' });
  return { success: data.success, errors: data.errors, isBilling: data.isBilling };
};

export const signupResendVerificationEmail = async () => {
  const data = await getRequest({ url: '/signup/resend-verification-email' });
  return { success: data.success, errors: data.errors };
};

export const signupSendPasswordResetEmail = async ({
  email,
}) => postRequestWithSuccessErrorsResponse({
  url: '/signup/send-password-reset-email',
  body: { email },
});

export const signupGetFirstNameEmailAndCompany = async () => {
  const data = await getRequest({ url: '/signup/get-first-name-email-and-company' });

  return {
    success: data.success,
    email: data.email,
    daysSinceJoining: data.days_since_joining,
    companyName: data.company_name,
    accountExecutiveName: data.account_executive_name,
    accountExecutiveEmail: data.account_executive_email,
    firstName: data.first_name,
  };
};

export const signupAtsOptions = async () => {
  const data = await getRequest({ url: '/signup/ats-options' });
  // data example:  [{"id":20,"name":"ADP Workforcenow"},{"id":31,"name":"Avature"},...]
  return [...data];
};

export const signupGetThirdPartyLibraryConfigs = async () => {
  const data = await getRequest({ url: '/signup/third-party-library-configs' });
  return {
    success: data.success,
    user: data.user,
    mixpanel: data.mixpanel,
    helpscoutSiteUrl: data.helpscoutSiteUrl,
    googleAnalytics: data.googleAnalytics,
  };
};

export const signupTrialRenew = async () => {
  const data = await postRequest({ url: '/signup/renew-trial', body: {} });
  return { success: data.success };
};
