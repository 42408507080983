/* eslint-disable */
import { signupGetThirdPartyLibraryConfigs } from '@/api/signup';
import { storeToRefs } from 'pinia';
import { useAppThirdPartyPlugins } from '@/stores/app/thirdParty';
import { initGoogleAnalytics } from './thirdParty/googleAnalytics';
import { initMixpanel } from './thirdParty/mixpanel';

export default {
  async install(app) {
    try {
      const responseJSON = await signupGetThirdPartyLibraryConfigs();
      if (responseJSON.success === true) {
        useAppThirdPartyPlugins().setThirdPartyConfigs(responseJSON);
        const {
          googleAnalytics, mixpanel,
        } = storeToRefs(useAppThirdPartyPlugins());

        if (mixpanel.value.isEnabled) {
          initMixpanel({ mixpanel: mixpanel.value });
        }

        if (googleAnalytics.value.isEnabled) {
          initGoogleAnalytics();
        }
      }
    } catch (e) {
      console.log(e);
      console.log(e.message);
      console.error('tracking error');
    }
  },
};
