import { defineStore } from 'pinia';

export const useAppThirdPartyPlugins = defineStore('appThirdPartyPlugins', {
  state: () => ({
    googleAnalytics: {},
    mixpanel: {},
    helpscoutSiteUrl: '',
    hubSpot: {
      email: null,
      token: null,
      timestamp: 0,
    },
    user: {},
  }),

  actions: {
    setThirdPartyConfigs(configs) {
      this.googleAnalytics = configs.googleAnalytics;
      this.mixpanel = configs.mixpanel;
      this.helpscoutSiteUrl = configs.helpscoutSiteUrl;
      this.user = configs.user;
    },
  },
});
